import React, { useState } from "react";
import { useNavigate,Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Row, Col, Container } from "reactstrap";
import { Typography } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import main_logo from "../../assets/images/main_logo.png";
import * as Constant from "../Constant";

function AdminForgotPassword() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: "",

    emailError: null,
    isLoading: false,
  });

  const validate = () => {
    let emailError = "";

    if (!state.email) {
      emailError = "Please Enter Your Email Address";
    }

    if (emailError) {
      setState({ ...state, emailError });

      return false;
    }
    return true;
  };

  const onSubmit = () => {
    if (validate()) {
      setState({
        ...state,
        emailError: null,
      });
      sendMail();
    }
  };

  const notify = (value) => {
    toast(value);
  };

  const storeEmail = async (value) => {
    try {
      await localStorage.setItem("useremailId", value);
      console.log("value", value);
    } catch (e) {
      // saving error
    }
  };

  const sendMail = async () => {
    setState({ ...state, isLoading: true });

    const formData = new FormData();
    formData.append("email", state.email);
    console.log(state.email);

    await fetch(Constant.ForgotPassword, {
      method: "POST",
      body: formData,
    })
      .then((Response) => Response.json())
      .then((data) => {
        // console.log(data);
        if (data.success === true) {
          notify(data.message);
          console.log(data.data.email);
          storeEmail(data.data.email);
          setTimeout(()=>{
            navigate("/admin/login");
           },5000)
        } else {
          notify(data.message);
        }
      });
  };

  return (
    <div className="main_class">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin - Forgot Password</title>
      </Helmet>
      <Card className="card mt-5 mb-5 pb-5">
        <CardContent>
          <Container>
            <Row className="text-center">
              <Col>
                <img
                  src={main_logo}
                  style={{
                    width: "150px",
                    marginTop: "25px",
                  }}
                  alt="Qudo"
                ></img>
              </Col>
            </Row>
            <Row className="text-center mt-5">
              <Col>
                <Typography style={{ color: "#808080" }}>
                  To Reset Your Password Please Enter Your Email Address.
                  <br></br> You May Need To Check Your Spam Folder.
                </Typography>
              </Col>
            </Row>
            <Row className="text-center mt-4">
              <Col>
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  className="textfield"
                  onChange={(event) =>
                    setState({ ...state, email: event.target.value })
                  }
                  required
                />
              </Col>
            </Row>
            <ToastContainer />
            <Row className="text-center mt-3">
              <Col>
                <Button variant="contained" className="forgotPasswordbutton"
                  onClick={() => onSubmit()}>
                  Submit
                </Button>
                <p className="mt-3">Or</p>
                <div className="mt-2 mb-4">
                  <span>Already have an account</span> <Link to='/admin/login' className="underline">Login</Link>
                </div>
              </Col>
            </Row>
          </Container>
        </CardContent>
      </Card>
    </div>
  );
}

export default AdminForgotPassword;
