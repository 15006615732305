import React, { useState, useEffect } from "react";
import "../admin/uploadroles.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import Card from "@mui/material/Card";
import { Row, Col, Container } from "reactstrap";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { Typography, Autocomplete,CircularProgress } from "@mui/material";
import Box from '@mui/material/Box';
import MenuItem from "@mui/material/MenuItem";

import * as Constant from "../Constant";
import moment from "moment";
import TextEditor from "./TextEditor";
import Header from "../../commonComponent/Header";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



// const industries = [
//   {
//     name: "Industry",
//     id:"1",
//   },
//   {
//     name: "Accounting ",
//     id:"2"
//   },
//   {
//     name: "Airlines/Aviation",
//     id:"3"
//   },
//   {
//     name: "Alternative Dispute Resolution",
//     id:"4"
//   },
// ]

const jobType = [
  {
    label: "Perm - Full Time",
    value: "perm_full_time"
  },
  {
    label: "Perm - Part Time",
    value: "perm_part_time"
  },
  {
    label: "Fixed Term - Full Time",
    value: "fixed_term_full_time"
  },
  {
    label: "Fixed Term - Part Time",
    value: "fixed_term_part_time"
  },
  {
    label: "Temporary",
    value: "temporary"
  },
  {
    label: "Contractor",
    value: "contractor"
  },
];


let updateState = {
  CountryList: [],
  country_id: "",
  country_name: "",
  continent: '',
  currencydata: '',
  StateList: [],
  state_id: "",
  state_name: "",
  continentError: false,
  // date: "",
  // time: "",
  job_title: "",
  job_type: "",
  organization_name: "",
  organization_value: "",
  minsalary: "",
  maxsalary: "",
  location: '',
  sector: '',
  industry: "",
  ad_start_date: moment(new Date()).format("YYYY-MM-DD"),
  ad_start_time: moment(new Date()).format("HH:mm"),
  ad_end_date: moment(new Date()).format("YYYY-MM-DD"),
  ad_end_time: moment(new Date()).format("HH:mm"),
  // job_description: "",
  job_titleError: false,
  organization_nameError: false,
  minsalaryError: false,
  maxsalaryError: false,
  countryError: false,
  stateError: false,
  industryError: false,
  jobTypeError: false,
  ad_start_dateError: false,
  ad_end_dateError: false,
  ad_start_timeError: false,
  ad_end_timeError: false,
  job_descriptionError: false,
  ad_textError: null,
  isLoading: false,
  checked: false,
  open: false,
  locationError: null,
  sectorError: null,
  // selectCountryValue:'',
  selectCountryId: null,
  // selectState:''
}


function UploadRoles() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [Users, setUsers] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [selectCountryValue, setselectCountryValue] = useState({ name: "" })
  const [selectState, setselectState] = useState({ name: "" })
  const [jobDescription, setJobDescription] = useState('');
  const [selectedJobType, setSelectedJobType] = useState("");
  const [continents, setContinents] = useState([]);
  const [selectContinent, setSelectContinent] = useState({ name: "" })
  const [currency, setCurrency] = useState([]);
  const [selectCurrency, setSelectCurrency] = useState({ currency_symbol: '', currency_code: '' });
  const [country, setCountry] = useState([]);
  const [state, setState] = useState(updateState);
  const [showErrors, setShowErrors] = useState('');

  let TodayDate = new Date();
  let month = TodayDate.getMonth() + 1;
  let day = TodayDate.getDate();
  let year = TodayDate.getFullYear();

  if (month < 10)
    month = '0' + month.toString();
  if (day < 10)
    day = '0' + day.toString();
  let maxDate = year + '-' + month + '-' + day;

  const textEditorhandle = (content) => {
    setJobDescription(content)
    setShowErrors((prev) => { return { ...showErrors, JobDescription: '' } })
  };

  useEffect(() => {
    if (id) {
      getJobDetails()
      getCurrency()
      getIndustries();
      getUsers();
    } else {
      getCurrency()
      getUsers();
      getIndustries();
    }
  }, []);

  useEffect(() => {
    getContinents()
  }, [state.continent])

  const handleJobDescriptionChange = (value) => {
    setJobDescription(value);
  };

  const getUsers = async () => {
    // setState({ ...state, isLoading: true });

    fetch(Constant.GetUsers, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          setUsers(data.data);
        }
      });
  };

  const getIndustries = async () => {
    // setState({ ...state, isLoading: true });

    fetch(Constant.GetIndustries, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          setIndustries(data.data);
        }
      });
  };

  const setUser = (e, newOrganisation) => {
    if (!!newOrganisation) {
      setState({
        ...state,
        organization_name: newOrganisation.organization_name,
        organization_value: newOrganisation.id,
        organization_nameError: false
      });
      setShowErrors((previous) => {
        return {
          ...previous, OrganisationNameError: ''
        }
      })
    }
    else {
      setState({
        ...state,
        organization_name: '',
      });
    }
  };


  const setJobType = (e, newJobType) => {
    if (!!newJobType) {
      const postElm = jobType.filter((item) => {
        return newJobType.label === item.label
      })
      const postValue = postElm[0].value
      setState({
        ...state,
        job_type: postValue,
        jobTypeError: false
      });
      setShowErrors((prev) => {
        return {
          ...prev, JObTypeError: ''
        }
      })
    } else {
      setState({
        ...state,
        job_type: '',
      });
    }
  };

  const setIndustry = (e, newIndustry) => {
    if (!!newIndustry) {
      setState({
        ...state,
        industry: newIndustry.industries,
        industryError: false
      });
      setShowErrors((prev) => { return { ...prev, IndustryError: '' } })
    } else {
      setState({ ...state, industry: '' });
    }
  };




  const getContinents = async () => {
    fetch(Constant.GetContinents, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setContinents(data.data);
        if (state.continent !== '') {
          const numericContinent = (state.continent)
          const filterData = data.data.filter((item) => {
            return numericContinent === item.name
          })[0]
          setSelectContinent(filterData);
          if (filterData?.code !== '') {
            getCountry(filterData?.code)
          }
        }
      });
  };


  const continentHandler = (e, newContinent) => {
    setselectCountryValue('')
    setCountry([])
    setselectState('')
    setState({ ...state, StateList: [] })
    if (!!newContinent) {
      setShowErrors((prev) => { return { ...prev, continentError: '' } })
      setSelectContinent(newContinent);
      getCountry(newContinent.code);
    } else {
      setSelectContinent('');
    }
  }

  const getCountry = async (code) => {
    const formData = new FormData();
    formData.append("continentCodes", JSON.stringify([code]));
    fetch(Constant.GetCountryList, {
      method: 'POST',
      body: formData,
    }).then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          setCountry(data.data[code]);
          if (state.location !== '') {
            const numericlocation = (state.location)
            const filterData = data.data[code]?.filter((item) => {
              return numericlocation === item.name
            })[0]
            setselectCountryValue(filterData)
            if (filterData?.code !== '') {
              gettingStates(filterData?.code)
            }
          }
        }
      }).catch((Error) => {
        console.log('-----uploadRoles Error', Error)
      })
  };

  const setCountryId = async (e, newvalue) => {
    setselectState('')
    setState({ ...state, StateList: [] })
    if (!!newvalue) {
      setselectCountryValue(newvalue)
      if (!id) {
        setSelectCurrency(newvalue)
      }
      setShowErrors((prev) => { return { ...prev, CountryNameError: '' } })
      gettingStates(newvalue.code)

    } else {
      setselectCountryValue("");
    }
  };

  const getCurrency = async () => {
    fetch(Constant.GetCurrency, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setCurrency(data.data);
      });
  };

  const currencyHandler = (e, newvalue) => {
    if (!!newvalue) {
      setSelectCurrency(newvalue)
      // setShowErrors((prev) => { return { ...prev, currencyError: '' } })
    }
  }

  const gettingStates = async (code) => {
    const formData = new FormData();
    formData.append("countryCodes", JSON.stringify([code]));
    fetch(Constant.GetStateList, {
      method: 'POST',
      body: formData,
    }).then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          setState({ ...state, StateList: data.data[code], countryError: false });
          if (state.sector !== '') {
            const numericSector = (state.sector)
            const filterData = data.data[code]?.filter((item) => {
              return numericSector === item.name
            })[0]
            setselectState(filterData)
          }
        }
      });
  }

  const setStateId = (e, newState) => {
    if (!!newState) {
      setselectState(newState)
      setState({ ...state, stateError: false })
      setShowErrors((prev) => { return { ...prev, StateNameError: '' } })
    } else {
      setselectState("")
    }
  };


  const handleChangeStartDate = (e) => {
    setState({ ...state, ad_start_date: e.target.value });
  };

  const handleChangeEndDate = (e) => {
    setState({ ...state, ad_end_date: e.target.value });
  };

  const changeStartTime = (e) => {
    setState({ ...state, ad_start_time: e.target.value });
  };

  const changeEndTime = (e) => {
    setState({ ...state, ad_end_time: e.target.value });
  };




  const handleChange = (event) => {
    setState({ ...state, currency: event.target.value });
  };

  const addJobRole = async () => {
    setState({ ...state, isLoading: true });
    const formData = new FormData();
    formData.append("job_title", state.job_title);
    formData.append("organization_name", state.organization_name);
    formData.append("min_salary", state.minsalary);
    formData.append("max_salary", state.maxsalary);
    formData.append("location", selectCountryValue.id);
    formData.append("sector", selectState.id);
    formData.append("industry", state.industry);
    formData.append("ad_start_date", state.ad_start_date);
    formData.append("ad_end_date", state.ad_end_date);
    formData.append("ad_start_time", state.ad_start_time);
    formData.append("ad_end_time", state.ad_end_time);
    formData.append("job_description", jobDescription);
    formData.append("created_by", "1");
    formData.append("userId", state.organization_value);
    formData.append("currency", selectCurrency.currency_symbol);
    formData.append("job_type", state.job_type);
    formData.append("continent", selectContinent.id);

    await fetch(Constant.AddRole, {
      method: "POST",
      body: formData,
    })
      .then((Response) => Response.json())
      .then((data) => {
        if (data.success === true) {
          setState({ ...state, isLoading: false });
          setState({
            job_title: "",
            organization_name: "",
            minsalary: "",
            maxsalary: "",
            location: "",
            sector: "",
            industry: "",
            ad_start_date: "",
            ad_start_time: "",
            ad_end_date: "",
            ad_end_time: "",
            job_type: '',
            // job_description: "",
            currency: "",
          });
          setJobDescription('')
          navigate("/admin/dashboard");
        } else {
          console.log('check 456 line uploadroles.js')
        }
      });
  };


  const validation = () => {

    let JobTitleError = ''
    let OrganisationNameError = ''
    let MinsalaryError = ''
    let MaxSalaryError = ''
    let IndustryError = ''
    let CountryNameError = ''
    let StateNameError = ''
    let continentError = ''
    let JobDescription = ''
    let JObTypeError = ''
    let currencyError = ''

    if (selectContinent?.name === '') {
      continentError = 'Please Select Continent'
    }
    if (state.job_title === '') {
      JobTitleError = 'Please Enter JobTitle'
    }
    if (state.organization_name === '') {
      OrganisationNameError = 'Please Select Organistation Name'
    }

    if (state.minsalary === '') {
      MinsalaryError = 'Please Enter Minimum Salary Range '
    }

    if (state.maxsalary === '') {
      MaxSalaryError = 'Please Enter Maximum Salary Range'
    } else if (Number(state.maxsalary) <= Number(state.minsalary)) {
      MaxSalaryError = 'Maximum Salary Greater Than Minimum Salary Range'
    }

    if (state.industry === '') {
      IndustryError = 'Please Select Industry'
    }

    if (selectCountryValue === '') {
      CountryNameError = 'Please Enter JobTitle'
    }

    if (selectState === '') {
      StateNameError = 'Please Enter JobTitle'
    }

    // if(state.currencydata === ''){
    //   currencyError = "Please Select Currency"
    // }

    if (state.job_type === '') {
      JObTypeError = 'Please Select Job Type'
    }

    let htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
    if (jobDescription == '') {
      JobDescription = 'Please Enter Some JobDescription'
    } else if (jobDescription.replace(htmlRegexG, '') == '') {
      JobDescription = 'Please Enter Some JobDescription'
    }
    //  || currencyError !==''
    if (JobTitleError !== '' || continentError !== '' || OrganisationNameError !== '' || MinsalaryError !== '' || MaxSalaryError !== ''
      || IndustryError !== '' || CountryNameError !== '' || StateNameError !== '' || JObTypeError !== '' || JobDescription !== '') {
      setShowErrors({
        ...showErrors,
        JobTitleError,
        continentError,
        OrganisationNameError,
        MinsalaryError,
        MaxSalaryError,
        IndustryError,
        CountryNameError,
        StateNameError,
        JobDescription,
        JObTypeError,
        currencyError

      })
      return false
    }
    return true
  }

  const onSubmit = (e) => {
    if (validation()) {
      addJobRole()
    }
  }

  const handleEditorChange_value = (e) => {
    setJobDescription(e)
  };

  const onEditRole = () => {
    navigate("/edit_job_role");
  };

  // ----------------------------Update Details

  const updateJobRole = async () => {
    if (validation()) {
      setState({ ...state, isLoading: true });
      var countryName = localStorage.getItem("Country_name");
      var stateName = localStorage.getItem("State_name");
      const formData = new FormData();
      formData.append("job_title", state.job_title);
      formData.append("organization_name", state.organization_name);
      formData.append("min_salary", state.minsalary);
      formData.append("max_salary", state.maxsalary);
      formData.append("currency", selectCurrency.currency_symbol);
      formData.append("location", selectCountryValue.id);
      formData.append("sector", selectState.id);
      formData.append("continent", selectContinent.id);
      formData.append("industry", state.industry);
      formData.append("ad_start_date", state.ad_start_date);
      formData.append("ad_start_time", state.ad_start_time);
      formData.append("ad_end_date", state.ad_end_date);
      formData.append("ad_end_time", state.ad_end_time);
      formData.append("job_type", state.job_type);
      formData.append("job_description", jobDescription);
      formData.append("userId", state.organization_value);


      await fetch(Constant.EditJobRole + "/" + id, {
        method: "POST",
        body: formData,
      })
        .then((Response) => Response.json())
        .then((data) => {
          setState({ ...state, isLoading: false });
          navigate("/admin/dashboard");
        });
    }
  };

  //----------------------------------- Get Details 

  const getJobDetails = async () => {
    await fetch(Constant.GetJobDetails + "/" + id, {
      method: "GET",
    }).then((Response) => Response.json())
      .then((data) => {
        if (data.success == true) {
          setState({ ...state, isLoading: false });
          setState((prevState) => ({
            ...state,
            job_title: data.data.job_title,
            organization_name: data.data.organization_name,
            minsalary: data.data.min_salary,
            maxsalary: data.data.max_salary,
            location: data.data.location,
            sector: data.data.sector,
            industry: data.data.industry,
            ad_start_date: moment(data.data.ad_start_date).format(
              "YYYY-MM-DD"
            ),
            ad_start_time: data.data.ad_start_time,
            ad_end_date: moment(data.data.ad_end_date).format("YYYY-MM-DD"),
            ad_end_time: data.data.ad_end_time,
            // job_description: data.data.job_description,
            // currency: data.data.currency,
            job_type: data.data.job_type,
            continent: data.data.continent,
            currencydata: data.data.currency
          }))
          setJobDescription(data.data.job_description);
        } else {
          setState({ ...state, isLoading: false });
          console.log('get api error line no 553 page uploadRoles.js ')
        }
      }).catch((e) => {
        console.log(e)
      })
  }


  useEffect(() => {
    const filterData = currency.filter((item) => {
      return state.currencydata == item.currency_symbol
    })[0]
    setSelectCurrency(filterData)
  }, [currency, state.currencydata])


  useEffect(() => {
    const FilterJobType = jobType.filter((item) => {
      if (state.job_type === item.value) {
        return item
      }
    })
    setSelectedJobType(FilterJobType[0]?.label);
  }, [state.job_type])


  const UserId = localStorage.getItem("userId");
  if (UserId == 1) {

    return (
      <div  className="position-relative">
        <Header logout={"/admin/login"} tabtitle={"Admin - Upload Role"} logoname={"Company"} logoNav={"/admin/dashboard"} />
        <Card className="upload_code_card">
          <div>
            {id ? <h3 className="roles_text my-4">Edit Job</h3> : <h3 className="roles_text my-4">Role Details</h3>}
          </div>

          <Container>
            <div className="d-flex align-items-end mb-4">
              <div className="w-100 mx-3 position-relative textfield-input">
                <FormControl variant="standard" className="w-100 " >
                  <Typography id="demo-customized-select-label" >
                    Job Title
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="w- "
                    value={state.job_title}
                    onChange={(event) => {
                      const ALPHA_NUMERIC_DASH_REGEX = /^[A-Za-z- ]+$/;
                      const value = event.target.value;
                      if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
                        return;
                      }
                      setState({ ...state, job_title: value, job_titleError: false })
                      setShowErrors((prev) => {
                        return { ...prev, JobTitleError: '' }
                      })
                    }
                    }
                  />
                </FormControl>
                {/* {state.job_titleError && <p className="input-message mb-0 position-absolute">Please Select Job Title</p>} */}
                {showErrors.JobTitleError && <p className="input-message mb-0 position-absolute">{showErrors.JobTitleError}</p>}
              </div>
              <div className="w-100 mx-3 position-relative textfield-input">
                <FormControl variant="standard" className="w-100 form-data-inputs" >
                  <Typography id="demo-customized-select-label">
                    Organisation Name
                  </Typography>
                  <Autocomplete
                    id="country-select-demo"
                    options={Users}
                    autoHighlight
                    getOptionLabel={(option) => option && option.organization_name ? option.organization_name : state.organization_name
                    }
                    renderOption={(props, option) => {
                      return (
                        <Box component="li"  {...props}>
                          {option.organization_name}
                        </Box>
                      )
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          // label="Choose a country"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )
                    }}
                    value={state.organization_name}
                    defaultValue={state.organization_name}
                    onChange={(e, newvalue) => setUser(e, newvalue)}
                  />

                </FormControl>
                {/* {state.organization_nameError && <p className="input-message mb-0 position-absolute">Please Select Organization name</p>} */}
                {showErrors.OrganisationNameError && <p className="input-message mb-0 position-absolute">{showErrors.OrganisationNameError}</p>}
              </div>
            </div>

            <div className="d-flex mb-4">
              <div className="w-100 mx-3 position-relative textfield-input" >
                <FormControl variant="standard" className="w-100 form-data-inputs">
                  <Typography id="demo-customized-select-label">
                    Continents
                  </Typography>
                  <Autocomplete
                    id="country-select-demo"
                    options={continents}
                    autoHighlight
                    getOptionLabel={(option) => option && option.name ? option.name : selectContinent?.name
                    }
                    renderOption={(props, option) => {
                      return (
                        <Box component="li"  {...props}>
                          {option.name}
                        </Box>
                      )
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          // label="Choose a country"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )
                    }}
                    value={selectContinent?.name}
                    defaultValue={selectContinent?.name}
                    onChange={(e, newContinent) => continentHandler(e, newContinent)}
                  />
                </FormControl>
                {showErrors.continentError && <p className="input-message mb-0 position-absolute">{showErrors.continentError}</p>}

                {/* {showErrors.CountryNameError && <p className="input-message mb-0 position-absolute">{showErrors.CountryNameError}</p>} */}
              </div>

              <div className="w-100 mx-3 position-relative textfield-input" >
                <FormControl variant="standard" className="w-100 form-data-inputs">
                  <Typography id="demo-customized-select-label">
                    Country
                  </Typography>
                  <Autocomplete
                    id="country-select-demo"
                    options={country || []}
                    autoHighlight
                    getOptionLabel={(option) => option && option.name ? option.name : selectCountryValue?.name
                    }
                    renderOption={(props, option) => {
                      return (
                        <Box component="li"  {...props}>
                          {option.name}
                        </Box>
                      )
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          // label="Choose a country"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )
                    }}
                    value={selectCountryValue?.name}
                    defaultValue={selectCountryValue?.name}
                    onChange={(e, newvalue) => setCountryId(e, newvalue)}
                  />
                  {/* <Dropdown
                    options={country.map((value, key) => (
                      <Typography key={key} value={value.country_name}>
                        {value.country_name}
                      </Typography>
                    ))}
                    value={selectCountryValue}
                    onChange={(e) => setCountryId(e)}
                    placeholder="Select an option"
                  /> */}
                </FormControl>
                {/* {state.countryError && <p className="input-message mb-0 position-absolute">Please Select Country</p>} */}
                {showErrors.CountryNameError && <p className="input-message mb-0 position-absolute">{showErrors.CountryNameError}</p>}
              </div>

              <div className="w-100 mx-3 position-relative textfield-input">
                <FormControl variant="standard" className="w-100 form-data-inputs" >
                  <Typography id="demo-customized-select-label">
                    State
                  </Typography>
                  <Autocomplete
                    id="country-select-demo"
                    options={state.StateList || []}
                    autoHighlight
                    getOptionLabel={(option) =>
                      option && option.name ? option.name : selectState?.name
                    }
                    renderOption={(props, option) => {
                      return (
                        <Box component="li"  {...props}>
                          {option.name}
                        </Box>
                      )
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )
                    }}
                    value={selectState?.name}
                    defaultValue={selectState?.name}
                    onChange={(e, newvalue) => setStateId(e, newvalue)}
                  />
                  {/* <Dropdown
                    options={state.StateList?.map((value, key) => (
                      <Typography key={key} value={value.state_name}>
                        {value.state_name}
                      </Typography>
                    ))}
                    value={selectState}
                    onChange={setStateId}
                    placeholder="Select an option"
                  /> */}
                </FormControl>
                {/* {state.stateError && <p className="input-message mb-0 position-absolute">Please Select State</p>} */}
                {showErrors.StateNameError && <p className="input-message mb-0 position-absolute">{showErrors.StateNameError}</p>}

              </div>
            </div>
            <div className="d-flex align-items-end mb-4">
              <div className="w-100 mx-3 position-relative textfield-input">
                <FormControl variant="standard" className="m-0 w-100 form-data-inputs">
                  <Typography id="demo-customized-select-label">
                    Currency
                  </Typography>
                  <Autocomplete
                    id="country-select-demo"
                    options={currency}
                    autoHighlight
                    getOptionLabel={(option) =>
                      option && option.currency_symbol ? option.currency_symbol : (selectCurrency?.currency_code && `(${selectCurrency?.currency_code}) ${selectCurrency?.currency_symbol} `)
                    }
                    renderOption={(props, option) => {
                      return (
                        <Box component="li"  {...props}>
                          ({option.currency_code}) {option.currency_symbol}
                        </Box>
                      )
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )
                    }}
                    value={selectCurrency?.currency_code && `(${selectCurrency?.currency_code}) ${selectCurrency?.currency_symbol} `}
                    defaultValue={selectCurrency?.currency_code && `(${selectCurrency?.currency_code}) ${selectCurrency?.currency_symbol} `}
                    onChange={(e, newvalue) => currencyHandler(e, newvalue)}
                  />
                </FormControl>
                {/* {showErrors.currencyError && <p className="input-message mb-0 position-absolute">{showErrors.currencyError}</p>} */}

              </div>

              <div className="w-100 mx-3 position-relative textfield-input">
                <Typography id="demo-customized-select-label">
                  Min Salary
                </Typography>
                <TextField
                  type='number'
                  id="outlined-basic"
                  variant="outlined"
                  className="w-100 "
                  value={state.minsalary}
                  onChange={(event) => {
                    setState({ ...state, minsalary: event.target.value, minsalaryError: false })
                    setShowErrors((prev) => { return { ...prev, MinsalaryError: '' } })
                  }
                  }
                />
                {/* {state.minsalaryError && <p className="input-message mb-0 position-absolute">Please Enter Minimum Salary</p>} */}
                {showErrors.MinsalaryError && <p className="input-message mb-0 position-absolute">{showErrors.MinsalaryError}</p>}
              </div>
              <div className="p-2">
                <Typography id="demo-customized-select-label">
                  To
                </Typography>
              </div>
              <div className="w-100 mx-3 position-relative textfield-input">
                <Typography id="demo-customized-select-label">
                  Max Salary
                </Typography>
                <TextField
                  type='number'
                  id="outlined-basic"
                  variant="outlined"
                  className="w-100 "
                  value={state.maxsalary}
                  onChange={(event) => {
                    setState({ ...state, maxsalary: event.target.value, maxsalaryError: false })
                    setShowErrors((prev) => { return { ...prev, MaxSalaryError: '' } })
                  }
                  }
                />
                {/* {state.maxsalaryError && <p className="input-message mb-0 position-absolute">Please Enter Maximum Salary</p>} */}
                {showErrors.MaxSalaryError && <p className="input-message mb-0 position-absolute">{showErrors.MaxSalaryError}</p>}

              </div>
            </div>


            <div className="d-flex  mb-5">
              <div className="m-0 w-100 mx-3 position-relative textfield-input">
                <FormControl variant="standard" className="m-0 w-100 form-data-inputs">
                  <Typography id="demo-customized-select-label">
                    Industry
                  </Typography>
                  <Autocomplete
                    id="country-select-demo"
                    options={industries}
                    autoHighlight
                    getOptionLabel={(option) =>
                      option && option.industries ? option.industries : state.industry
                    }
                    renderOption={(props, option) => {
                      return (
                        <Box component="li"  {...props}>
                          {option.industries}
                        </Box>
                      )
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )
                    }}
                    value={state.industry}
                    defaultValue={state.industry}
                    onChange={(e, newvalue) => setIndustry(e, newvalue)}
                  />
                  {/* <Dropdown
                    options={industries.map((value, key) => (
                      <Typography key={key} value={value.industries}>
                        {value.industries}
                      </Typography>
                    ))}
                    value={state.industry}
                    onChange={(e) => setIndustry(e)}
                    placeholder="Select an option"
                  /> */}
                </FormControl>
                {/* {state.industryError && <p className="input-message mb-0 position-absolute">Please Select Industry</p>} */}
                {showErrors.IndustryError && <p className="input-message mb-0 position-absolute">{showErrors.IndustryError}</p>}

              </div>

              <div className="w-100 mx-3 position-relative textfield-input" >
                <FormControl variant="standard" className="w-100 form-data-inputs" >
                  <Typography id="demo-customized-select-label">
                    Job Type
                  </Typography>
                  <Autocomplete
                    id="country-select-demo"
                    options={jobType}
                    autoHighlight
                    getOptionLabel={(option) =>
                      option && option.label ? option.label : selectedJobType
                    }
                    renderOption={(props, option) => {
                      return (
                        <Box component="li"   {...props}>
                          {option.label}
                        </Box>
                      )
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )
                    }}
                    value={selectedJobType}
                    defaultValue={selectedJobType}
                    onChange={(e, newvalue) => setJobType(e, newvalue)}
                  />
                  {/* <Dropdown
                    options={jobType.map((value, key) => (
                      <Typography key={key} value={value.label}>
                        {value.label}
                      </Typography>
                    ))}
                    onChange={(e) => setJobType(e)}
                    value={FilterJobType[0]?.label}
                    placeholder="Select an option"
                  /> */}
                </FormControl>
                {/* {state.jobTypeError && <p className="input-message mb-0 position-absolute">Please Select Job Type</p>} */}
                {showErrors.JObTypeError && <p className="input-message mb-0 position-absolute">{showErrors.JObTypeError}</p>}

              </div>
            </div>

            <div className="d-flex  mb-5">
              <div className="w-100 mx-3 position-relative textfield-input">
                <TextField
                  id="date"
                  label="Advert Start Date"
                  type="date"
                  onChange={handleChangeStartDate}
                  className="w-100 "
                  inputProps={{
                    min: maxDate,
                  }}
                  disablePast
                  value={state.ad_start_date}
                  // sx={{ width: 210 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {/* {state.ad_start_dateError && <p className="input-message mb-0 position-absolute">Please Select Advert Start Date</p>} */}

              </div>
              <div className="w-100 mx-3 textfield-input">
                <TextField
                  id="date"
                  label="Advert End Date"
                  type="date"
                  className="w-100 "
                  inputProps={{
                    min: maxDate,
                  }}
                  disablePast
                  value={state.ad_end_date}
                  sx={{ width: 210 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeEndDate}
                />
                {/* {state.ad_end_dateError && <p className="input-message mb-0 position-absolute">Please Select Advert End Date</p>} */}
              </div>
            </div>

            <div className="d-flex  mb-5">
              <div className="w-100 mx-3 position-relative textfield-input">
                <TextField
                  className="w-100 "
                  id="time"
                  label="Advert Start Time"
                  type="time"
                  value={state.ad_start_time}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  sx={{ width: 210 }}
                  onChange={changeStartTime}
                />
                {/* {state.ad_start_timeError && <p className="input-message mb-0 position-absolute">Please Select Advert Start Time</p>} */}
              </div>
              <div className="w-100 mx-3 position-relative textfield-input">
                <TextField
                  className="w-100 "
                  id="time"
                  label="Advert End Time"
                  type="time"
                  value={state.ad_end_time}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  sx={{ width: 210 }}
                  onChange={changeEndTime}
                />
                {/* {state.ad_end_timeError && <p className="input-message mb-0 position-absolute">Please Select Advert End Time</p>} */}
              </div>
            </div>
            <div>
              {/* <TextEditor
                jobDescription={jobDescription}
                handleEditorChange_value={handleJobDescriptionChange}
              /> */}
              <Typography id="demo-customized-select-label" className="mx-3 my-1">
                Job Advert
              </Typography>
              <ReactQuill
                className="mx-3 mb-4"
                style={{ height: '150px' }}
                theme="snow"
                value={jobDescription} // Set the default value here
                onChange={textEditorhandle}
                modules={{
                  toolbar: [
                    // [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ 'size': ['small', false, 'large', 'huge'] }],
                    ['bold', 'italic', 'underline', 'blockquote'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['link'],
                    // ['clean'],
                  ],
                }}
                formats={[
                  'header',
                  'bold',
                  'italic',
                  'underline',
                  'blockquote',
                  'list',
                  'bullet',
                  'link',
                  'size',
                ]}
              />
              {/* {state.job_descriptionError && <p className="input-message mb-0 mt-4 ml-3 position-absolute">Please Write some description</p>} */}
              {showErrors.JobDescription && <p className="input-message mb-0 mt-4 ml-3 position-absolute">{showErrors.JobDescription}</p>}

            </div>
            {id ?
              <div style={{ marginTop: 70 }} className="d-flex w-25 mx-auto">
                <button onClick={() => navigate("/admin/dashboard")} className=" bg-white cancel-btn d-flex m-auto mt-5 px-4 py-2 rounded-lg " >
                  Cancel
                </button>
                <button onClick={() => updateJobRole()} className="border-0 d-flex m-auto  px-4 py-2 rounded-lg text-white upload-btn" >
                  Submit
                </button>
              </div> :
              <div style={{ marginTop: 70 }} className="d-flex w-25 mx-auto">
                <button onClick={() => navigate("/admin/dashboard")} className=" bg-white cancel-btn d-flex m-auto mt-5 px-4 py-2 rounded-lg " >
                  Cancel
                </button>
                <button onClick={(e) => onSubmit(e)} className="border-0 d-flex m-auto px-4 py-2 rounded-lg text-white upload-btn"  >
                  Upload A role
                </button>
              </div>
            }

          </Container>
        </Card>
        { state.isLoading && <div className="d-flex justify-content-center align-items-center position-absolute w-100 h-100" style={{top:0, background: 'rgb(35, 131, 239 ,0.1) '}}>
                <CircularProgress  size='55px' />
              </div>
              }
      </div>
    );
  } else {
    return <Navigate to="/" replace />;
  }
}

export default UploadRoles;
