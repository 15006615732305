import React, { useEffect, useState } from "react";

// import "./admindashboard.css";
import { Navigate, useNavigate } from "react-router-dom";
import * as Constant from "../Constant";

//images
import Header from "../../commonComponent/Header";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  Button, CircularProgress
} from "@mui/material";
import CardComp from "./CardComp";


function EmployerView() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    jobList: [],
    isLoading: false,

    searchText: "",
    oepn: false,
  });

  const [query, setQuery] = useState("")

  // const handleButtonClick = () => {
  //   if (state.open === false) {
  //     setState({ ...state, open: true });
  //   } else {
  //     setState({ ...state, open: false });
  //   }
  // };

  // const logout = async () => {
  //   localStorage.clear();
  //   sessionStorage.clear();
  //   window.location.href = "/";
  // };

  // const onUploadRole = () => {
  //   navigate("/admin/uploadjobs");
  // };

  useEffect(() => {
    getJob();
  }, []);

  const getJob = async () => {
    setState({ ...state, isLoading: true });
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    await fetch(Constant.GetJobListUser + "/" + userId, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          setState({ ...state, isLoading: false });
          setState({ ...state, jobList: data.data });
        }
      });
  };


  const UserId = localStorage.getItem("userId");
  const job_list = state.jobList;

  return (
    <div>

      <Header logout={"/"} tabtitle={"Qudo - Employer Dashboard"} logoname={"Employer"} logoNav={"/employer/dashboard"}
        settingItemList={[{ 'name': 'Employee Dashboard', 'navigate': '/employer/dashboard' },
        { 'name': ' Employeer Upload Role', 'navigate': '/employer/uploadjob' }
        ]}
      />

      <Card className="upload_code_card ">
        <CardContent>
          <Card className="roles_text_card">
            <CardContent>
              <text className="roles_text">Your Roles</text>
            </CardContent>
          </Card>
          <Button
            variant="contained"
            className="Upload_role text-white"
            onClick={() => navigate("/employer/profile")}
          >
            View Profile
          </Button>
          <div className="d-flex align-items-end justify-content-end ">
            <input className="mr-5 p-2 rounded-pill" placeholder="Enter Job Title" onChange={event => setQuery(event.target.value)} />
            <Button
              variant="contained"
              className="button_text text-white"
              onClick={() => navigate("/employer/uploadjob")}
            >
              Upload A role
            </Button>
          </div>
        </CardContent>

        <div>
          <div id="layout-content" className="layout-content-wrapper">
            <Card className="table_card">
              <CardContent>
                <text className="header_roles">Roles</text>
                <text className="header_application">No of Applications</text>
                <text className="header_active">Active</text>
              </CardContent>
            </Card>

            <div className="position-relative" style={{ minHeight: 300 }}>
              {job_list?.filter(post => {
                if (query === '') {
                  return post;
                } else if (post.job_title.toLowerCase().includes(query.toLowerCase())) {
                  return post;
                }
              }).map((item, i) => (
                <CardComp item={item} key={i} dashboardname={'employee'} />
              ))}
              {state.isLoading && <div  className="d-flex justify-content-center align-items-center position-absolute w-100 h-100">
                <CircularProgress size='55px'/>
              </div>
              }
              {
                (job_list.length === 0 && state.isLoading == false ) && <div className="d-flex justify-content-center align-items-center position-absolute w-100 h-100 font-weight-bold" style={{   color: '#707070', fontSize:28 }}>
                  Applications Not Found
                </div>
              }
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default EmployerView;
