import React, { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom';

export default function PrivateRoutes({children}) {
    const [token, setToken] = useState("");
    const [roleIds, setRoleIds] = useState("");
    useEffect(() => {
        const tok = localStorage.getItem("token");
        const roleId = localStorage.getItem("roleId");
        setRoleIds(roleId);
        setToken(tok);
    }, [])

    if(!token && roleIds == 3) {
        return <Navigate to={'/employer/dashboard'} replace />
    }

    if(!token && roleIds == 1) {
        return <Navigate to={'/admin/dashboard'} replace />
    }
    
    return <Outlet />;
}
