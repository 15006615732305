import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { Button, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Link, useNavigate, useParams } from "react-router-dom";
import { EditJobStatus } from "../Constant";
import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";

export default function CardComp({ item, dashboardname }) {
  const [isActive, setActive] = useState(false);
  const [jobDesModal ,setJobDesModal] = useState(false)
  const id = useParams()
  const navigate = useNavigate();
  const onEditRole = (value, dashboardname) => {
    navigate(`/${dashboardname}/editjob/` + value.id, {
      state: {
        values: value
      },
    });
  };

  const onChange = async (e) => {
    setActive(e.target.checked)
    await fetch(EditJobStatus + '/' + item.id, {
      method: 'POST',
      body: JSON.stringify({ "job_status": e.target.checked ? "open" : "close" }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((Response) => Response.json())
      .then((data) => {
        // console.log("77",data)
      })
  };

  useEffect(() => {
    if (item.job_status == "open") {
      setActive(true)
    } else {
      setActive(false);
    }
  }, [item])

  return (
    <Card className="upload_code_card2">
      <CardContent>
        <Container>
          <Row>
            <Col sm="12" md="4" lg="4" xs="12" xl="4">
              <div  style={{cursor:'pointer'}} onClick={()=>{setJobDesModal(true)}}>
                <Typography className="mt-3  ml-3" style={{ fontWeight: "bold" }}>
                  Job Title:-
                </Typography>
                <Typography className=" mb-3 ml-3">{item.job_title}</Typography>

              </div>
              {dashboardname !== 'employee' &&
                <>
                  <Typography className="mt-3  ml-3" style={{ fontWeight: "bold" }}>
                  Organisation Name:-
                  </Typography>
                  <Typography className="ml-3">{item.organization_name}</Typography>
                </>
              }
              {/* <Typography className="mt-3  ml-3" style={{ fontWeight: "bold" }}>
                Job Description:-
              </Typography>
              <Typography className="ml-3">
                <div className=" job_description"
                  dangerouslySetInnerHTML={{ __html: item.job_description }}
                />
              </Typography> */}
            </Col>
            <Col sm="12" md="4" lg="4" xs="12" xl="4" className="d-flex">
              <Button className="center d-flex m-auto  justify-content-center text-white w-25 rounded-pill " style={{ fontSize: 30, background: "#007aff", outline: 0, height: 50 }}
                onClick={() =>
                  //  navigate("/appliedUsers", { state: { jobDetails: item } })
                  navigate(`/${dashboardname}/appliedUsers`, {
                    state: {
                      jobDetails: item,
                      dashboardname: dashboardname
                    },
                  })
                }
              >
                {item.applied_count}
              </Button>
            </Col>
            <Col

              sm="12"
              md="4"
              lg="4"
              xs="12"
              xl="4"
              style={{ paddingLeft: 140 }}
              className="d-flex flex-column justify-content-center"
            >
              <div className="switch-cnt">
                <input type='checkbox' checked={isActive} onChange={onChange} className="inputcheckbox"></input>
                <div className={isActive ? "active-switch-cnt" : "inactive-switch-cnt"}>
                  <div className={isActive ? "active-switch" : "inactive-switch"}></div>
                </div>
              </div>
              <Button
                variant="contained"
                className="button_text2 text-white"
                onClick={() => onEditRole(item, dashboardname)}
              >
                Edit Job Role
              </Button>
            </Col>
          </Row>
        </Container>
        <Modal
          open={jobDesModal}
          onClose={()=>{setJobDesModal(false)}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="d-flex"
        >
          <Box className='job-description-modal bg-light w-50 m-auto p-5 elm' >
          <Typography className=" mb-3 ml-3 job-modal-title text-center font-weight-bold">{item.job_title}</Typography>
          <Typography className="mt-3 mb-2 ml-3" style={{ fontWeight: "bold" }}>
                Job Description:-
              </Typography>
              <Typography className="ml-3">
                <div className=" job_description"
                  dangerouslySetInnerHTML={{ __html: item.job_description }}
                />
              </Typography>
              <Button className="m-auto d-flex job-modal-btn text-white px-3" onClick={()=>{setJobDesModal(false)}}>Close</Button>
              </Box>
        </Modal>
      </CardContent>
    </Card>
  );
}
