import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../commonComponent/Header";
import Avtar from '../../assets/images/Avatar.jpg'
import PdfFile from "./PdfFile";
import {
  CircularProgress
} from "@mui/material";

const UserProfileDetails = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [userProfile, setUserProfile] = useState({})
  const [loading, setloading] = useState(false)
  useEffect(() => {
    getUserPrfoileApi()
  }, [])

  const getUserPrfoileApi = async () => {
    try {
      setloading(true)
      let userId = location.state.jobDetails.id
      let apiData = await fetch(`https://qudo.airwebsolutions.co.uk/api/get_user_profile/${userId}`)
      let response = await apiData.json()
      if (response.success == true) {
        setloading(false)
        setUserProfile(response.data[0])
      } else {
        console.log('-----response is not successful line 21 userprofiledetail page')
      }
    } catch (error) {
      console.log('-----catch response is not successful line 21 userprofiledetail page')
    }
  }

  let { email, first_name, last_name, logo, pdf, phone } = userProfile

  return <>
    <div className="position-relative">
      {
        location.state.dashboardname == 'employee' ?
          <Header logout={"/"} tabtitle={"Qudo - Applicants"} logoname={"Employer"} logoNav={"/employer/dashboard"}
            settingItemList={[{ 'name': 'Employee Dashboard', 'navigate': '/employer/dashboard' },
            { 'name': ' Employeer Upload Role', 'navigate': '/employer/uploadjob' }
            ]} /> :
          <Header logout={"/admin/login"} tabtitle={"Qudo - Applicants"} logoname={"Admin"} logoNav={"/admin/dashboard"} />
      }
      <main className="my-5 ">
        <div className="d-flex h-50 m-auto  profile-card rounded p-4" style={{ gap: 70, maxWidth: 900 }}>
          <div>
            {logo ? <img src={logo} alt='user-profile' style={{ height: 200, borderRadius: 20 }} /> : <img src={Avtar} alt='user-profile' style={{ height: 200, borderRadius: 20 }} />}
          </div>
          <div className="p-3">
            <div className="mb-5">
              <h5 className="font-weight-normal user-profile-label m-0" >Full Name</h5>
              <h3 className="font-weight-bold text-capitalize mb-0  mt-2">{first_name}  {last_name}</h3>
            </div>
            <div className="d-flex" style={{ gap: 80 }}>
              <div>
                <h5 className="font-weight-normal user-profile-label m-0">Email</h5>
                <h5 className="font-weight-bold mb-0 mt-2">{email}</h5>
              </div>
              <div>
                <h5 className="font-weight-normal user-profile-label m-0">Phone</h5>
                <h5 className="font-weight-bold text-uppercase mb-0  mt-2">{phone}</h5>
              </div>
            </div>

          </div>
        </div>
        <hr className="my-5"></hr>
        <h2 className="text-uppercase text-center font-weight-bold" style={{ color: '#0068FF' }}>Resume</h2>
        <div className="w-75 mx-auto p-3 my-4" style={{ background: '#F5F5F5' }}>
          {pdf ? <PdfFile pdfurl={pdf} /> : <div className="align-items-center d-flex justify-content-center  border" style={{ minHeight: 300 }}>
            <h1 className="text-center text-uppercase" style={{ color: '#D0D0D0' }}>No RESUME UPLOADED</h1>
          </div>}
        </div>
        <button className="text-center d-flex m-auto px-5 py-2 rounded font-weight-bold mt-4 text-white border-0"
          style={{ background: 'rgb(0, 104, 255)' }} onClick={() => navigate(-1)}>Back</button>
      </main>
      {loading && <div className="d-flex justify-content-center align-items-center position-absolute w-100 h-100" style={{ top: 0, background: 'rgb(35, 131, 239 ,0.1)' }}>
        <CircularProgress size='55px' />
      </div>
      }
    </div>
  </>
}
export default UserProfileDetails