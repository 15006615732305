import React, { useState } from "react";
import "./adminlogin.css";
import { useNavigate } from "react-router-dom";
//images
import main_logo from "../../assets/images/main_logo.png";
import Glogo from "../../assets/images/Glogo.png";
import slacklogo from "../../assets/images/slacklogo.png";
import windowslogo from "../../assets/images/windowslogo.png";
import wordpresslogo from "../../assets/images/wordpresslogo.png";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import ClipLoader from "react-spinners/ClipLoader";
import { Row, Col, Container } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import eyeImg from '../../assets/images/eye.png'

// api file
import * as Constant from "../Constant";

function AdminLogin() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: "",
    password: "",
    emailError: null,
    passwordError: null,
    isLoading: false,
  });


  const [showPassword, setShowPassword] = useState("password")

  const passwordHandler = () => {
    if (showPassword === 'password') {
      setShowPassword('text')
      return;
    }
    setShowPassword('password')
  }
  const validate = () => {
    let emailError = "";
    let passwordError = "";
    var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
    if (!state.email) {
      emailError = "Email Cannot Be Empty.";
    } else if (!reg.test(state.email)) {
      emailError = "Please Enter a Valid Email Address.";
    }

    if (!!state.email) {
      console.log('====', emailError)
      emailError = '';
      setState({ ...state, emailError: '' });
    }

    if (!state.password) {
      passwordError = "Password Cannot be empty.";
    }
    if (!!state.password) {
      passwordError = '';
      setState({ ...state, passwordError: '' });
    }

    if (emailError || passwordError) {
      setState({ ...state, passwordError, emailError });
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    if (validate()) {
      // setState({
      //   ...state,
      //   emailError: null,
      //   passwordError: null,
      // });
      console.log('---', state)
      onLogin();
    }
  };

  const storeUserId = async (userId) => {
    try {
      localStorage.setItem("userId", userId);
      console.log(userId);
    } catch (e) { }
  };

  const storeToken = (value) => {
    try {
      localStorage.setItem("token", value);
      console.log("token", value);
    } catch (e) {
      // saving error
    }
  };

  const storeRoleId = async (userId) => {
    try {
      localStorage.setItem("roleId", userId);
      console.log(userId);
    } catch (e) { }
  };

  const onLogin = async () => {
    setState({ isLoading: true });

    const formData = new FormData();
    formData.append("email", state.email);
    formData.append("password", state.password);

    fetch(Constant.LoginUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data.userId);
        storeRoleId(data.data.role_id);
        if (data.data.userId === 1) {
          storeUserId(data.data.userId);
          storeToken(data.data.token);
          toast("You have logged in successfully.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setState({
            email: "",
            password: "",
            isLoading: false,
          });
          navigate("/admin/dashboard");
        } else {
          toast("Your login details are incorrect. Please try again.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setState({
            ...state, isLoading: false
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
          toast("Oops!!!... There is no Internet Connection", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  const submitHandler =(e)=>{
    if(e.keyCode === 13){
      onSubmit(e)
    }
  }

  return (
    <div className="main_class">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Qudo - Admin Login</title>
      </Helmet>
      <div className="card">
        <div className="center_class">
          <img
            src={main_logo}
            style={{
              width: "130px",
              marginTop: "25px",
            }}
            alt="Qudo"
          ></img>
        </div>
        <div className="center_class">
          <text className="welcometext">Welcome To Qudo</text>
        </div>


        <Container onKeyDown={(e)=>submitHandler(e)}>
          <Row>
            <Col md="1" lg="1" xl="1"></Col>
            <Col sm="12" xs="12" md="5" lg="5" xl="5">
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                className="text_field"
                onChange={(event) =>
                  setState({ ...state, email: event.target.value })
                }
                required
              />
              {!!state.emailError && (
                <Typography style={{ color: "red", marginLeft: "10px" }}>
                  {state.emailError}
                </Typography>
              )}
            </Col>
            <Col sm="12" xs="12" md="5" lg="5" xl="5">
            <div className="position-relative">
              <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                className="text_field"
                type={showPassword}
                onChange={(event) =>
                  setState({ ...state, password: event.target.value })
                }
                required
              />
              <div className="show-password" onClick={() => passwordHandler()}>
                <img src={eyeImg} alt='password-hide' width={20} height={20} />
              </div>
              </div>
              {!!state.passwordError && (
                <Typography style={{ color: "red", marginLeft: "10px" }}>
                  {state.passwordError}
                </Typography>
              )}
            </Col>
            <Col md="1" lg="1" xl="1"></Col>
          </Row>
          <Row>
            <Col className="mt-4 text-center">
              <Button
                type="submit"
                disabled={state.isLoading}
                variant="contained"
                style={{ width: "300px", height: "35px", borderRadius: "10px" }}
                onClick={() => onSubmit()}
              >
                {state.isLoading ? (
                  <ClipLoader color="#ffffff" height="20" width="20" />
                ) : (
                  "Log In"
                )}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-3 mb-4 ">
              <span
              className="forgot-password"
                style={{ color: "#707070", cursor: "pointer" }}
                onClick={() => navigate("/admin/forgotpassword")}
              >
                Forgot Password?
              </span>
            </Col>
          </Row>
          <ToastContainer />
        </Container>

        {/* <div className="center_class_qudo my-3" >
          <text className="choose_qudo">Why choose QUDO ?</text>
        </div> */}

        {/* <div className="center_class mt-3">
          <text className="brandtext">Brands we work with</text>
        </div>
        <div className="center_class">
          <div style={{ flexDirection: "row" }}>
            <img src={slacklogo} className="mr-2" alt="slack"></img>
            <img src={Glogo}  className="mr-2" alt="glogo"></img>
            <img
              src={windowslogo}
              alt="windows"
            ></img>
            <img src={wordpresslogo} alt="wordpress"></img>
          </div>
        </div> */}
        {/* <div className="contacttextdiv">
          <text className="contacttext">Contact Us</text>
        </div> */}
      </div>
    </div>
  );
}

export default AdminLogin;
