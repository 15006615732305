import * as React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Row, Col, Container } from "reactstrap";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";

import * as Constant from "../Constant";

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,

      userList: [],

      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      activeTab:'all',
      selected: false,
    };

    this.getUserList();
  }

  userApprove = async (id) => {
    this.setState({ isLoading: true });
    console.log(id);

    await fetch(Constant.UserApprove + "/" + id, {
      method: "POST",
    })
      .then((Response) => Response.json())
      .then((data) => {
        console.log(data);
        // window.location.reload();
        this.getUserList()
      });
  };

  userRejected = async (id) => {
    this.setState({ isLoading: true });

    await fetch(Constant.UserReject + "/" + id, {
      method: "POST",
    })
      .then((Response) => Response.json())
      .then((data) => {
        console.log(data);
        // window.location.reload();
        this.getUserList()
      });
  };

  getUserList = async () => {
    this.setState({ isLoading: true });

    await fetch(Constant.GetUserList, {
      method: "GET",
    })
      .then((Response) => Response.json())
      .then((data) => {
        console.log("data" ,data)
        if (data.success === true) {
          this.setState({ userList: data.data });
        } else {
          console.log("No Record Found");
        }
      });
  };



  filterHandler =(elm)=>{
   this.setState({
    activeTab: elm
   })
  }
  render() {

    const {userList, activeTab} = this.state;
    
    const arr = userList.filter((item) => {
      if(activeTab == "all") {
        return true
      } else {
        return item.approved == activeTab
      }
    })

    return (
      <div>
        <ul className="d-flex  justify-content-end text-white">
          <li className="px-5   user-list-tabs " onClick={()=>{this.filterHandler("all")}}>All</li>
          <li className="px-5  user-list-tabs ml-3"  onClick={()=>{this.filterHandler(1)}}>APPROVED</li>
          <li className="px-5 user-list-tabs ml-3 mr-3"  onClick={()=>{this.filterHandler(0)}}>REJECTED</li>
        </ul>
       
        {arr.map((item) => (
          <Card className="upload_code_card ">
            <CardContent>
              <Container>
                <Row>
                  <Col sm="12" md="4" lg="4" xl="4" xs="12">
                    <Typography style={{ fontWeight: "bold" }}>
                      Organisation Name:-
                    </Typography>
                    <Typography>
                      {item.organization_name}
                    </Typography>
                    <Typography
                      style={{ fontWeight: "bold", marginTop: "12px" }}
                    >
                      Email:-
                    </Typography>
                    <Typography>{item.email}</Typography>
                  </Col>
                  <Col sm="12" md="3" lg="3" xl="3" xs="12">
                    <Typography style={{ fontWeight: "bold" }}>
                      Phone:-
                    </Typography>
                    <Typography>{item.phone}</Typography>
                  </Col>
                  <Col sm="12" md="5" lg="5" xl="5" xs="12">
                    <Row style={{ marginTop: "60px" }}>
                      <Col>
                        <Button
                          variant="contained"
                          className="approve_reject_button"
                          onClick={() =>
                            Swal.fire({
                              text:
                                "Are you sure you want to reject the registration of " +
                                item.first_name +
                                " " +
                                item.last_name +
                                "?",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonText: " Ok",
                              confirmButtonColor: "#2383EF",
                              cancelButtonText: "Cancel",
                              cancelButtonColor: "#2383EF",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                this.userRejected(item.id);
                              } else if (
                                result.dismiss === Swal.DismissReason.cancel
                              ) {
                              }
                            })
                          }
                        >
                          {item.approved === 0 ? "Rejected" : "Reject"}
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          variant="contained"
                          className="approve_reject_button"
                          onClick={() =>
                            Swal.fire({
                              text:
                                "Are you sure you want to approve the registration of " +
                                item.first_name +
                                " " +
                                item.last_name +
                                "?",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonText: " Ok",
                              confirmButtonColor: "#2383EF",
                              cancelButtonText: "Cancel",
                              cancelButtonColor: "#2383EF",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                this.userApprove(item.id);
                              } else if (
                                result.dismiss === Swal.DismissReason.cancel
                              ) {
                              }
                            })
                          }
                        >
                          {item.approved === 1 ? "Approved" : "Approve"}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </CardContent>
          </Card>
        ))}
      </div>
    );
  }
}

export default UserList;
