import React, { useState } from "react";


import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import UserList from "../employer/UserList";
import { Navigate, useNavigate } from "react-router-dom";
import Header from "../../commonComponent/Header";


function ViewUser() {
  const [state, setState] = useState({
    oepn: false,
  });
  const navigate = useNavigate();

  const UserId = localStorage.getItem("userId");
  if (UserId == 1) {
    return (
      <div>
     <Header logout={"/admin/login"} tabtitle={"Users"} logoname={"Company"} logoNav ={"/admin/dashboard"}/>
        <Card className="upload_code_card ">
          <CardContent>
            <Card className="roles_text_card">
              <CardContent>
                <text className="roles_text">Users</text>
              </CardContent>
            </Card>
            <Card>
              <UserList />
            </Card>
          </CardContent>
        </Card>
      </div>
    );
  } else {
    return <Navigate to="/" replace />;
  }
}
export default ViewUser;
