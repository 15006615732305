import React, { useState } from "react";
// import "./adminlogin.css";
import "./employeeregister.css";
import { Link, useNavigate } from "react-router-dom";

//images
import main_logo from "../../assets/images/main_logo.png";

import Button from "@mui/material/Button";
import { Typography, Snackbar, TextField, InputLabel, TextareaAutosize } from "@mui/material";
import { Helmet } from "react-helmet";
import ClipLoader from "react-spinners/ClipLoader";
import { Row, Col, Container } from "reactstrap";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ToastContainer, toast } from "react-toastify";

// api file
import * as Constant from "../Constant";

function EmployerRegister() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    point_of_contact:'',
    email: "",
    organisation: "",
    phone: "",
    telephone: "",
    password: "",
    address: "",
    c_password: "",
    img_name: {},
    img_nameCheck: '',
    imgnameError: null,
    firstnameError: null,
    lastnameError: null,
    poinToContactError:null,
    emailError: null,
    organisationError: null,
    phoneError: null,
    telephoneError: null,
    passwordError: null,
    c_passwordError: null,
    addressError: null,
    isLoading: false,
  });
  const [openToaster, setOpenToaster] = useState({
    open: false,
    vertical: "top",
    horizontal: "center"
  });

  const { vertical, horizontal, open } = openToaster;


  const handleClose = () => {
    setOpenToaster({ ...openToaster, open: false });
  };

  const validate = () => {
    let firstnameError = ""
    let imgnameError = ""
    let lastnameError = ""
    let emailError = ""
    let phoneError = ""
    let passwordError = ""
    let organisationError = ""
    let c_passwordError = ""
    let addressError = ""
    let telephoneError = ""
     let poinToContactError = ''

    if (!state.first_name) {
      firstnameError = "First Name Cannot Be Empty.";
    }
    // if (!state.point_of_contact) {
    //   poinToContactError = "First Name Cannot Be Empty.";
    // }

    if (!state.last_name) {
      lastnameError = "Last Name Cannot Be Empty.";
    }

    if (!state.address) {
      addressError = "Address Cannot Be Empty.";
    }

    // if (state.img_nameCheck == '') {
    //   imgnameError = "Please select Company Logo";
    // }
    if (!state.organisation) {
      organisationError = "Organisation Name Cannot Be Empty.";
    }

    var phonereg = new RegExp("^[0-9]{10}$")
    if (!state.phone) {
      phoneError = "Phone Number Cannot Be Empty.";
    } else {
      if ((state.phone.length <= 7)) {
        phoneError = "Phone number is invalid"
      }
      if ((state.phone.length > 13)) {
        phoneError = "Phone number is invalid"
      }
    }

    // if (!state.telephone) {
    //   telephoneError = "Telephone Number Cannot Be Empty.";
    // } else {
    //   if ((state.telephone.length <= 7)) {
    //     telephoneError = "Telephone number is invalid"
    //   }
    //   if ((state.telephone.length > 13)) {
    //     telephoneError = "Telephone number is invalid"
    //   }
    // }

    var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
    if (!state.email) {
      emailError = "Email Cannot Be Empty.";
    } else {
      if (!reg.test(state.email)) {
        emailError = "Please Enter a Valid Email Address.";
      }
    }

    if (!state.password) {
      passwordError = "Password Cannot Be Empty.";
    } else {
      if ((state.password.length <= 6)) {
        passwordError = "Password length must be minimum 8 characters"
      }
    }

    if (!state.c_password) {
      c_passwordError = "Confirm Password Cannot Be Empty.";
    } else {
      if (state.password !== state.c_password) {
        c_passwordError = "The Password does not match. Try again.";
      }
    }

    if (
      firstnameError ||
      lastnameError ||
      passwordError ||
      imgnameError ||
      phoneError ||
      emailError ||
      organisationError ||
      c_passwordError ||
      addressError 
    ) {
      setState({
        ...state,
        firstnameError,
        lastnameError,
        emailError,
        organisationError,
        phoneError,
        passwordError,
        c_passwordError,
        imgnameError,
        addressError
      });
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    if (validate()) {
      onRegister();
    }
  };

  const storeEmail = async (value) => {
    try {
      localStorage.setItem("email", value);
    } catch (e) { }
  };

  const storeData = async (value) => {
    try {
      localStorage.setItem("userId", value);
    } catch (e) { }
  };

  const onRegister = async () => {
    setState({ isLoading: true });
    const formData = new FormData();
    formData.append("first_name", state.first_name);
    formData.append("last_name", state.last_name);
    formData.append("point_of_contact", state.point_of_contact);
    formData.append("email", state.email);
    formData.append("phone", state.phone);
    formData.append("telephone", state.telephone);
    formData.append("password", state.password);
    formData.append("address", state.address);
    formData.append("c_password", state.c_password);
    formData.append("organization_name", state.organisation);
    if (Object.keys(state.img_name).length !== 0) {
      formData.append("image", state.img_name[0]);
    }
    formData.append("role_id", 3);
    await fetch(Constant.RegisterUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          setOpenToaster({ ...openToaster, open: true });
          storeEmail(state.emailAddress);
          storeData(data.userid);
          setState({
            first_name: "",
            last_name: "",
            point_of_contact: "",
            email: "",
            organisation: '',
            img_name: {},
            phone: "",
            telephone: "",

            password: "",
            address: "",
            c_password: "",
            role_id: "",
            isLoading: false
          });
          setTimeout(() => { navigate("/") }, 4000)
        } else {
          console.log('nksndkdjfsdfjskd')
          let message = (data.data.email[0])
          {
            message && toast(message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,

            });
          }
          setState({ ...state, isLoading: false });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {

        } else {
          console.error("Error:!!!!", error);
        }
        setState({ ...state, isLoading: false });
      });
  };

  return (
    <div className="main_class">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Employee - Register</title>
      </Helmet>

      <Card className="register_card d-flex justify-content-center align-items-center">
        <CardContent className="w-100">
          <div className="center_class">
            <img
              src={main_logo}
              style={{
                width: "130px",
              }}
              alt="Qudo"
            ></img>
          </div>
          <div className="center_class">
            <text className="welcometext">Welcome To Qudo Sign Up</text>
          </div>

          <Container className="text-center register-form ">
            <Row className="mt-4">
              <Col>
                <InputLabel className='d-flex m-auto' style={{ width: '95%' }}>Organisation Name*</InputLabel>
                <TextField
                  id="outlined-basic"
                  // label="Organisation Name"
                  variant="outlined"
                  className="textfield w-100"
                  autoComplete="off"
                  onChange={(event) =>
                    setState({ ...state, organisation: event.target.value, organisationError: null })
                  }
                  required
                />
                {!!state.organisationError && (
                  <Typography className='text-left ml-3' style={{ color: "red" }}>
                    {state.organisationError}
                  </Typography>
                )}
              </Col>
              <Col >
                <InputLabel className='d-flex m-auto' style={{ width: '95%' }}>Organisation Logo (optional)</InputLabel>
                {/* <TextField
                  id="img_name"
                  variant="outlined"
                  className="textfield"
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  autoComplete="off"
                  onChange={(e) => {
                    setState({ ...state, img_name: e.target.files[0], imgnameError: null, img_nameCheck: e.target.files })
                  }}
                  required
                /> */}


                <input style={{ border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: 10 }} className="textfield p-1 w-100"
                  type="file" accept="image/png, image/jpg, image/jpeg" id="inputGroupFile01" onChange={(e) => {

                    setState({ ...state, img_name: e.target.files, imgnameError: null, img_nameCheck: e.target.files })
                  }} />

                {!!state.imgnameError && (
                  <Typography className='text-left ml-3' style={{ color: "red" }}>
                    {state.imgnameError}
                  </Typography>
                )}
              </Col>
            </Row>
            <Row className="mt-2 ">
              <Col >
                <InputLabel className='d-flex m-auto' style={{ width: '95%' }}>First Name*</InputLabel>
                <TextField
                  id="first_name"
                  // label="First Name"
                  variant="outlined"
                  autoComplete="off"
                  className="textfield w-100"
                  onChange={(event) =>
                    setState({ ...state, first_name: event.target.value, firstnameError: null })
                  }
                  required
                />
                {!!state.firstnameError && (
                  <Typography className='text-left ml-3' style={{ color: "red" }}>
                    {state.firstnameError}
                  </Typography>
                )}
              </Col>
              <Col>
                <InputLabel className='d-flex m-auto' style={{ width: '95%' }}>Last Name*</InputLabel>
                <TextField
                  id="last_name"
                  // label="Last Name"
                  variant="outlined"
                  className="textfield w-100"
                  autoComplete="off"
                  onChange={(event) =>
                    setState({ ...state, last_name: event.target.value, lastnameError: null })
                  }
                  required
                />
                {!!state.lastnameError && (
                  <Typography className='text-left ml-3' style={{ color: "red" }}>
                    {state.lastnameError}
                  </Typography>
                )}
              </Col>
              <Col >
                <InputLabel className='d-flex m-auto' style={{ width: '95%' }}>Point of Contact (optional)</InputLabel>
                <TextField
                  id="point_of_contact"
                  // label="First Name"
                  variant="outlined"
                  autoComplete="off"
                  className="textfield w-100"
                  onChange={(event) =>
                    setState({ ...state, point_of_contact: event.target.value, poinToContactError: null })
                  }
                  required
                />
                {!!state.poinToContactError && (
                  <Typography className='text-left ml-3' style={{ color: "red" }}>
                    {state.poinToContactError}
                  </Typography>
                )}
              </Col>
            </Row>
            <Row className="mt-2">

              <Col>
                <InputLabel className='d-flex m-auto' style={{ width: '95%' }}>Phone*</InputLabel>
                <TextField
                  id="outlined-basic"
                  // label="Phone"
                  variant="outlined"
                  type="number"
                  autoComplete="off"
                  className="textfield w-100"
                  max={10}
                  onChange={(event) =>
                    setState({ ...state, phone: event.target.value, phoneError: null })
                  }
                  required
                />
                {!!state.phoneError && (
                  <Typography className='text-left ml-3' style={{ color: "red" }}>
                    {state.phoneError}
                  </Typography>
                )}
              </Col>
              <Col>
                <InputLabel className='d-flex m-auto' style={{ width: '95%' }}>Telephone (optional)</InputLabel>
                <TextField
                  id="outlined-basic"
                  // label="Phone"
                  variant="outlined"
                  type="number"
                  autoComplete="off"
                  className="textfield w-100"
                  max={10}
                  onChange={(event) =>
                    setState({ ...state, telephone: event.target.value, telephoneError: null })
                  }
                  required
                />
                {!!state.telephoneError && (
                  <Typography className='text-left ml-3' style={{ color: "red" }}>
                    {state.telephoneError}
                  </Typography>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <InputLabel className='d-flex m-auto' style={{ width: '95%' }}>Address*</InputLabel>
                <TextField
                  id="outlined-basic"
                  // label="Password"
                  variant="outlined"
                  className="textfield w-100"
                  autoComplete="off"
                  type="address"
                  onChange={(event) =>
                    setState({ ...state, address: event.target.value, addressError: null })
                  }
                  required
                />

                {!!state.addressError && (
                  <Typography className='text-left ml-3' style={{ color: "red" }}>
                    {state.addressError}
                  </Typography>
                )}
              </Col>
              <Col>
                  <InputLabel className='d-flex m-auto' style={{ width: '95%' }}>Email*</InputLabel>
                  <TextField
                    id="outlined-basic"
                    // label="Email"
                    autoComplete="off"
                    variant="outlined"
                    className="textfield w-100"
                    onChange={(event) =>
                      setState({ ...state, email: event.target.value, emailError: null })
                    }
                    required
                  />
                  {!!state.emailError && (
                    <Typography className='text-left ml-3' style={{ color: "red" }}>
                      {state.emailError}
                    </Typography>
                  )}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <InputLabel className='d-flex m-auto' style={{ width: '95%' }}>Password*</InputLabel>
                <TextField
                  id="outlined-basic"
                  // label="Password"
                  variant="outlined"
                  className="textfield w-100"
                  autoComplete="off"
                  type="password"
                  onChange={(event) =>
                    setState({ ...state, password: event.target.value, passwordError: null })
                  }
                  required
                />
                {!!state.passwordError && (
                  <Typography className='text-left ml-3' style={{ color: "red" }}>
                    {state.passwordError}
                  </Typography>
                )}
              </Col>
              <Col>
                <InputLabel className='d-flex m-auto' style={{ width: '95%' }}>Confirm Password*</InputLabel>
                <TextField
                  id="outlined-basic"
                  // label="Confirm Password"
                  variant="outlined"
                  className="textfield w-100"
                  inputProps={{
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                  type="password"
                  onChange={(event) =>
                    setState({ ...state, c_password: event.target.value, c_passwordError: null })
                  }
                  required
                />
                {!!state.c_passwordError && (
                  <Typography className='text-left ml-3' style={{ color: "red" }}>
                    {state.c_passwordError}
                  </Typography>
                )}
              </Col>
            </Row>

            <Row className="mt-2">
              <Col>
                <Button
                  variant="contained"
                  style={{
                    width: "50%",
                    height: "35px",
                    borderRadius: "10px",
                    marginBottom: "10px",
                  }}
                  disabled={state.isLoading}
                  onClick={() => onSubmit()}
                >
                  {state.isLoading ? (
                    <ClipLoader color="#ffffff" height="20" width="20" />
                  ) : (
                    " Sign Up"
                  )}
                </Button>
              </Col>
            </Row>
            <span>Or</span>
            <div className="mt-1 ">
              <span>Already have an account</span> <Link to='/' className="underline">Login</Link>
            </div>
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={open}
              onClose={handleClose}
              message="User Registration Successfully , Please check your email for verification"
              key={vertical + horizontal}
            />
            <ToastContainer />

          </Container>
        </CardContent>
      </Card>
    </div>
  );
}

export default EmployerRegister;
