import React, { useEffect, useState } from "react";

import "./admindashboard.css";
import { Navigate, useNavigate } from "react-router-dom";
import * as Constant from "../Constant";

//images
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { TextField, Button, Typography,CircularProgress } from "@mui/material";
import moment from "moment";
import CardComp from "../employer/CardComp";
import Header from "../../commonComponent/Header";

function AdminDashboard() {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  var date = moment(new Date()).format("YYYY-MM-DD");

  const renderHTML = (escapedHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: escapedHTML },
    });
  const [state, setState] = useState({
    jobList: [],
    isLoading: false,
    searchText: "",
    open: false,
  });

  const [query, setQuery] = useState("")
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const activejobstatus = (value) => {
    let newdate = [];
    newdate = moment(value).format("YYYY-MM-DD");
    if (newdate <= date) {
      setChecked(false);
      console.log(checked);
    } else {
      if (checked == false) {
        setChecked(true);
        console.log("==", checked);
      }
    }
  };

  const onUploadRole = () => {
    navigate("/admin/uploadjobs");
  };

  useEffect(() => {
    getJob();
  }, []);

  const getJob = async () => {
    setState({ ...state, isLoading: true });
    const token = localStorage.getItem("token");

    await fetch(Constant.GetJobList, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        
        if (data.success === true) {
          let i;
          for (i = 0; i <= data.data.length; i++) {
            setState({
              ...state,
              isLoading: false ,
              jobList: data.data,
            });
            // activejobstatus(data.data[i].ad_end_date);
          }
        }
      });
  };


  // const searchJob = async (text) => {
  //   setState({ ...state, isLoading: true });
  //   const token = localStorage.getItem("token");
  //   await fetch(Constant.SearchCompany + "/" + text, {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   })
  //     .then((Response) => Response.json())
  //     .then((data) => {
  //       if (data.success == true) {
  //         setState({ ...state, jobList: data.data });
  //       }
  //     });
  // };

 

  const UserId = localStorage.getItem("userId");
  const job_list = state.jobList;
  if (UserId == 1) {
    return (
      <div>
     
        <Header logout={"/admin/login"} tabtitle={"Qudo - Admin Dashboard"} logoname={"Admin"} logoNav ={"/admin/dashboard"}/>
        <Card className="upload_code_card ">
          <CardContent>
            <Card className="roles_text_card">
              <CardContent>
                <text className="roles_text">Your Roles</text>
              </CardContent>
            </Card>
            <Button
              variant="contained"
              className="Upload_role text-white "
              onClick={() => navigate("/admin/viewuser")}
            >
              Manage Employers
            </Button>

            {/* <div className="button_class">
              <div className="search_card">
                <TextField
                  id="outlined-basic"
                  label="Search Job Role"
                  variant="outlined"
                  margin="normal"
                  style={{ width: "100%" }}
                  // value={state.searchText}
                  onInput={() => {
                    state.searchText.length <= 0
                      ? getJob()
                      : searchJob(state.searchText);
                  }}
                  onChange={(event) =>
                    setState({ ...state, searchText: event.target.value })
                  }
                />
              </div>

              <Button
                variant="contained"
                className="button_text text-white"
                onClick={() => onUploadRole()}
              >
                Upload A role
              </Button>
            </div> */}

            <div className="d-flex align-items-end justify-content-end ">
            <input className="mr-5 p-2 rounded-pill" placeholder="Enter Job Title" onChange={event => setQuery(event.target.value)} />
            <Button
              variant="contained"
              className="button_text text-white"
              onClick={() => onUploadRole()}
            >
              Upload A role
            </Button>

          </div>

          </CardContent>

          <div id="layout-content" className="layout-content-wrapper">
            <Card className="table_card">
              <CardContent>
                <text className="header_roles">Roles</text>
                <text className="header_application">No of Applications</text>
                <text className="header_active">Active</text>
              </CardContent>
            </Card>
            <div className="position-relative" style={{ minHeight: 300 }}>
            {job_list?.filter(post => {
            if (query === '') {
              return post;
            } else if (post.job_title.toLowerCase().includes(query.toLowerCase())) {
              return post;
            }
            }).map((item, i) => (
              <CardComp item={item} key={i} dashboardname={'admin'}/>
            ))}
             {state.isLoading && <div className="d-flex justify-content-center align-items-center position-absolute w-100 h-100">
                <CircularProgress />
              </div>
              }
              {
                (job_list.length === 0 && state.isLoading == false ) && <div className="d-flex justify-content-center align-items-center position-absolute w-100 h-100 font-weight-bold" style={{   color: '#707070', fontSize:28 }}>
                  Applications Not Found
                </div>
              }
            </div>
          </div>
        </Card>
      </div>
    );
  } else {
    return <Navigate to="/" replace />;
  }
}

export default AdminDashboard;
