import React, { useEffect, useState } from "react";
import "./employerdashboard.css";
import { Helmet } from "react-helmet";
import { Navigate, useNavigate } from "react-router-dom";

//images
import chart from "../../assets/images/chart.png";
import Qudo_light from "../../assets/images/Qudo_light.png";
import settings from "../../assets/images/settings.png";

import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import { Row, Col, Container } from "reactstrap";
import { Typography,CircularProgress } from "@mui/material";
import Header from "../../commonComponent/Header";
import * as Constant from "../Constant";

function EmployerDashboard() {
  const navigate = useNavigate();
  const [uploadLogo, setUploadLogo] = useState(null);
  const [logo, setLogo] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const formData = new FormData();
    formData.append("file", uploadLogo);
    formData.append("user_id", userId);
    await fetch(Constant.SetLogo, {
      method: "POST",
      body: formData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((Response) => Response.json())
      .then((data) => {
        console.log(data)
        if (data.error_status) {
          alert(data.error);
        } else {
          alert("Logo updated Successfully");
        }
      });
  };

  const [state, setState] = useState({
    isLoading: false,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    organization_name:'',
    avtar: "",
    loadingImage: false,
    open: false,
  });

  const logout = async () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
  };

  const handleButtonClick = () => {
    if (state.open === false) {
      setState({ ...state, open: true });
    } else {
      setState({ ...state, open: false });
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    setState({ ...state, isLoading: true });
    const token = await localStorage.getItem("token");
    const userId = await localStorage.getItem("userId");

    await fetch(Constant.GetProfile + "/" + userId, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((Response) => Response.json())
      .then((data) => {
        if (data.success === true) {
          setState({ ...state, isLoading: false });
          setState({
            ...state,
            first_name: data.data[0].first_name,
            last_name: data.data[0].last_name,
            email: data.data[0].email,
            phone: data.data[0].phone,
            organization_name: data.data[0].organization_name,
            isLoading: false,
            avatar: data.data[0].logo,
          });
          
          setLogo(data.data[0].logo);
          console.log(data.data[0].logo)
        } else {
          setState({ isLoading: false });
        }
      });
  };

  const uploadNewLogo = (files) => {
    setUploadLogo(files);
    var src = URL.createObjectURL(files);
    var preview = document.getElementById("logo-image");
    preview.src = src;
    preview.style.display = "block";
  };

  var userId = localStorage.getItem("userId");
  if (userId) {
    return (
      <div className="position-relative h-100">
     <Header logout={"/"} tabtitle={"Qudo - Employee Dashboard"} logoname={"Company"} 
     logoNav ={"/employer/dashboard"} 
     settingItemList={[{'name':'Employee Dashboard','navigate':'/employer/dashboard' },
     {'name':' Employeer Upload Role','navigate':'/employer/uploadjob' }
     ]} />

        <Card className="mt-5 Profile_card">
          <CardContent>
            <Card className="roles_text_card">
              <CardContent>
                <text className="roles_text">Your Profile</text>
              </CardContent>
            </Card>
            <Button
              variant="contained"
              className="Upload_role"
              onClick={() => navigate("/employer/dashboard")}
            >
              View Job Dashboard
            </Button>
            <Container>
              <Row className="mt-2">
                <Col sm="12" md="4" lg="4" xl="4" xs="12" className="mt-4 ">
                  <div>
                    <img
                      id="logo-image"
                      style={{height:300}}
                      className="logoImg"
                      src={
                        logo ? logo : require("../../assets/images/Avatar.jpg")
                      }
                      alt=""
                    ></img>
                    <form onSubmit={handleSubmit}>
                      <input
                        type="file"
                        className="logoInput"
                        onChange={(e) => uploadNewLogo(e.target.files[0])}
                      />
                      <button type="submit" className="logoBtn">
                        Update Logo
                      </button>
                    </form>
                  </div>
                </Col>
                <Col sm="12" xs="12" md="8" lg="8" xl="8" className="mt-5 ">
                  <Typography className="text_style">Name:</Typography>
                  <Typography>
                    {state.first_name} {state.last_name}
                  </Typography>

                  <Typography className="text_style mt-2">Email:</Typography>
                  <Typography>{state.email}</Typography>

                  <Typography className="text_style mt-2">Phone:</Typography>
                  <Typography>{state.phone}</Typography>

                  <Typography className="text_style mt-2">Organisation Name:</Typography>
                  <Typography>{state.organization_name}</Typography>
                </Col>
              </Row>
            </Container>
          </CardContent>
          
        </Card>

        {state.isLoading && <div className="d-flex justify-content-center align-items-center position-absolute w-100 h-100" style={{top:0 ,background: 'rgb(35, 131, 239 ,0.1)'}}>
                <CircularProgress  size='60px' />
              </div>
              }
      </div>
    );
  } else {
    return <Navigate to="/" replace />;
  }
}

export default EmployerDashboard;
