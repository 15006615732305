import React, { useState } from 'react'
import { Helmet } from "react-helmet";
import QudoLogo from "../assets/images/Qudo_light.png";
import './Header.css'
import settings from "../assets/images/settings.png";
import { Link, useNavigate } from 'react-router-dom';
import chart from "../assets/images/chart.png";

const Header = ({ logout, tabtitle, logoname, logoNav, settingItemList }) => {
    let [showSetting, setShowSetting] = useState(false)
    let navigate = useNavigate()
    const logoutHandler = () => {
        localStorage.removeItem('userId')
        localStorage.removeItem('roleId')
        localStorage.removeItem('token')
        localStorage.removeItem('organization_name')

        navigate(logout)
    }

    return <>
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{tabtitle}</title>
            </Helmet>
            <header className='header-container px-2' >
                <div className='d-flex align-items-end justify-content-between py-5 header-subcnt'>
                    <div className='d-flex align-items-end'>
                        <Link to={logoNav} className=''><img src={QudoLogo} width={160}  /></Link>
                        <h2 className='m-0 font-weight-bold ml-4'>{logoname}</h2>
                    </div>
                    <div className='d-flex '>
                        <Link  className='w-25 mr-5'> <img src={chart} alt='qudochart' width={35} className='mr-5' /></Link>
                        <div className='position-relative'>
                            <img src={settings} className='setting-img ' alt="qudo" width={35} onClick={() => { setShowSetting(!showSetting) }} />
                            {showSetting && <div className='position-absolute setting-items'>
                                <button className=' setting-list bg-transparent border-0 p-2 text-white w-100' onClick={() => { logoutHandler() }}>Logout</button>
                                {settingItemList && settingItemList.map((item) => {
                                    const { name, navigate } = item
                                    return <>
                                        <Link to={navigate} className='setting-list d-flex justify-content-center p-2 text-decoration-none text-white'>{name}</Link>
                                    </>
                                })}
                            </div>}
                        </div>
                    </div>
                </div>
            </header>
        </div>
    </>
}

export default Header 