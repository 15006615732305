import React, { useState, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom';

export default function PrivateRoutes() {
    const [token, setToken] = useState("");
    
    useEffect(() => {
        const tok = localStorage.getItem("token");
        setToken(tok)
    }, [])

    return token ? <Navigate to={'/employer/dashboard'} replace /> : <Outlet />;
}
